.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.mapouter{
  position:relative;
  text-align:right;
  height:500px;
  width: 100%;
}
.gmap_canvas {
  overflow:hidden;
  background:none!important;
  height:500px;
  width: 100%;
}

 /* .video {
  width: 100%;
  height:auto;
  margin-bottom: 10%;
}  */
.col-centered{
  float: none;
  margin: 0 auto;
  }

@media screen and (max-width: 768px) {
  .text-intro-wrap .text-intro h1 {
    font-size: 45px;
}
}
@media screen and (max-width: 365px) {
  .text-intro-wrap .text-intro h1 {
    font-size: 35px;
}
}

#ourstory{
  margin-right: -5px;
  margin-left: -5px;
}
.probootstrap-section.probootstrap-section-colored,
.probootstrap-section.probootstrap-section-colored-darker,.flexslider{
background: #DEB887
}
.probootstrap-footer{
  background: #CD853F;
}

.probootstrap-groom-bride .probootstrap-divider span, h2, h3,h1,h4,  a{
  color:#CD853F;
 
}
.probootstrap-navbar.scrolled .navbar-nav>li.active>a {
  color: #CD853F!important;
}


.text-intro-wrap .text-intro h1 ,h2, h3, h4, .probootstrap-groom-bride h2 ,
.probootstrap-navbar .navbar-brand  {
  font-family: 'Dancing Script', cursive;
  text-transform: none
}

.text-intro-wrap .text-intro h2, h4 {
  font-family: 'Karla', sans-serif;
}

.row #intro {
  margin-top : 60px
}

h3 {
  font-size: 2.999em;
}
.probootstrap-section #gallery{
  padding-top:0px
}

#aboutus {
  padding-bottom: 0px
}

@media screen and (max-width: 1030px) {
 
.flexslider .slides .pic1{
  background-position: 70%
}

.flexslider .slides .pic2{
  background-position: 0
}
.text-intro-wrap {
  top: 55%;
}
}

